


















































































































































@import "@/assets/scss/variables.scss";

@import "bulma/sass/utilities/initial-variables";

$size-large: 1.2rem;

@import "bulma/sass/utilities/functions";
@import "bulma/sass/utilities/derived-variables";
@import "bulma/sass/utilities/mixins";
@import "bulma/sass/utilities/controls";
@import "bulma/sass/elements/icon";
@import "bulma/sass/elements/button";
@import "bulma/sass/form/_all";
@import "bulma/sass/components/dropdown";

.add-column{
  min-width: 310px;
  scroll-snap-align: start;
  .dropdown{
    &.is-active{
      .button{
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
      }
    }
    .dropdown-trigger{
      .button{
        background: #F5F6F6;
        width: 280px;
        height: 49px;
        justify-content: left;
        outline: none;
        &:focus{
          border-color: transparent;
        }
        &:focus:not(:active){
          box-shadow: none;
        }
      }
    }
  }
  .dropdown-menu{
    padding-top: 0px;
    .dropdown-content{
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    hr{
      width: 50%;
      transform: translateX(50%);
    }
    .dropdown-item{
      min-width: 280px;
      min-width: 280px;
      width: 280px;
      p{
        font-size: 12px;
      }
      p:first-child{
        font-size: 18px;
        svg{
          height: 15px;
        }
      }
    }
  }
  .mobile-button{
    display: block;
    position: absolute !important;
    bottom: 29px;
    right: 24px;
    margin: 4px;
    background: $dark !important;
    svg {
      transform: scale(1);
    }
    &:before{
      background: rgba($primary-color, 0.7) !important;
    }
    &:after{
      box-shadow: none !important;
    }
  }
}

