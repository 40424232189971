



























































































































































@import "@/assets/scss/variables.scss";

@import "bulma/sass/utilities/initial-variables";
@import "bulma/sass/utilities/functions";

$primary: $secondary-color;

@import "bulma/sass/utilities/derived-variables";
@import "bulma/sass/utilities/mixins";

$navbar-height : 5.4rem;
$navbar-background-color: $secondary-color;
$navbar-item-color: $light;
$navbar-item-hover-color: $light;
$navbar-item-hover-background-color: $primary-color;
$navbar-item-active-color: $primary-color;
$navbar-item-active-background-color: $primary-color;
$navbar-dropdown-background-color: $primary-color;
$navbar-dropdown-border-top: 2px solid $primary-color;
$navbar-dropdown-arrow: $light;
$navbar-dropdown-item-hover-color: $light;
$navbar-dropdown-item-hover-background-color: $dark;
$navbar-breakpoint: 640px;

@import "bulma/sass/components/navbar";

.navbar {
  .navbar-brand {
    padding-left: 20px;
    @media print {
      display: flex;
      justify-content: center;
      align-items: center;
      a.navbar-item {
        line-height: normal;
      }
    }
  }

  .burger {
    @media print {
      display: none;
    }
  }

  .navbar-menu {
    @media print {
      display: none;
    }
    @include breakpoint(mobile) {
      padding: 0px;
    }

    .navbar-end {
      background: $dark;

      @include breakpoint(mobile) {
        background-color: $secondary-color;

        > a.navbar-item:hover, > a.navbar-item.is-active, > a.navbar-item:focus-within {
          background-color: rgba($dark, 0.05);
        }
      }
      .navbar-item {
        text-transform: uppercase;
        text-decoration: none;
        padding: 0.5rem 1.75rem;

        svg {
          margin-right: 5px;
        }

        @include breakpoint(mobile) {
          padding-top: 1rem;
          padding-bottom: 1rem;
          color: $light;
        }

        &.has-dropdown.is-hoverable {
            &:hover {
              background: $primary-color;
            }
            .is-language{
              text-decoration: underline !important;
              font-weight: 800;
            }
            &::before {
              content: '';
              position: absolute;
              width: 1px;
              height: 50%;
              background: $white;
              top: 50%;
              left: 0;
              transform: translateY(-50%);

              @include breakpoint(mobile) {
                display: none;
              }
            }

            @include breakpoint(mobile) {
              padding: 0;
              .navbar-link {
                display: none;
              }
              .navbar-dropdown {
                display: flex;
                background: $dark;
                .navbar-item {
                  &:hover {
                    background: rgba($light, 0.05);
                  }
                }
              }
            }
        }
      }
    }
  }
}
