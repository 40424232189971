






































































@import "@/assets/scss/variables.scss";

main {
  @media screen {
    background-color: $background-color;
    display: flex;
    height: 100%;
    flex-direction: column;

    #columns {
      -webkit-overflow-scrolling: touch;
      touch-action: auto;
      flex-grow: 1;
      padding-left: 2rem;
      padding-right: 2rem;
      display: flex;
      user-select: none;
      scroll-snap-type: x mandatory;
      overflow-x: scroll;
      overflow-y: hidden;
    }
  }
  @media print {
    #columns {
      display: none;
    }
  }
}

// .list-leave-to {
//   transform: translate(0%, -100%);
// }
.list-leave-active, .list-move {
  transition: transform 0.5s;
}
