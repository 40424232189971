.form {
  outline: none;
}

.btn {
  width: auto;
  text-align: left;
  color: white;
  font-weight: 600;
  text-shadow: 0px 1px 1px rgba(black, 0.20);
  border: none;
  border-radius: 4px;
  padding: 14px 16px;
  background-color: $secondary-color;
  cursor: pointer;
  outline: none;
  box-shadow: none;
  appearance: none;

  &:hover {
    box-shadow: 0px 0px 4px rgba(black, 0.2);
  }

  &:active {
    box-shadow: none;
  }

  &.btn-icon {
    color: inherit;
    background-color: transparent;
    text-shadow: none;
    font-weight: 400;

    &:hover {
      box-shadow: none;

      i {
        text-shadow: 0px 0px 4px rgba(black, 0.2);
      }
    }
  }

  &.btn-large {
    display: block;
    margin-bottom: 4px;
    width: 100%;

    &.btn-icon {
      text-align: center;
    }
  }

  &.btn-circle {
    background-color: $secondary-color;
    color: white;
    border-radius: 50%;
    position: relative;
    &:before {
      position: absolute;
      top: 0px;
      left: 0px;
      content: "";
      width: 100%;
      height: 100%;
      background-color: lighten($secondary-color, 10);
      border-radius: 50%;
      transform: scale(0);
      transition: transform 0.1s ease-out;
      transform-origin: 50% 50%;
    }
    &:after {
      position: absolute;
      top: 0px;
      left: 0px;
      content: "";
      width: 100%;
      height: 100%;
      border-radius: 50%;
      box-shadow: 1px 1px 3px darken($secondary-color, 20);
    }
    &:hover {
      &:before {
        transform: scale(1);
      }
    }
    i {
      position: relative;
    }
  }

  &.danger {
    background-color: $danger-color;

    &.btn-icon i {
      color: white;
    }

    &.btn-icon.btn-circle {
      &:before {
        background-color: lighten($danger-color, 10);
      }
      &:after {
        box-shadow: 1px 1px 3px darken($danger-color, 20);
      }
    }
  }

  i {
    font-size: 24px;

    &.icon-small {
      font-size: 16px;
    }
  }
}

.inpt {
  height: $form-element-height;
  background-color: white;
  text-indent: 14px;
  border: none;
  margin-bottom: 4px;
  font-size: 1.1rem;
  border-radius: 4px;
  &[type="search"] {
    appearance: none;
    &::-webkit-search-cancel-button {
      margin-right: 17px;
    }
    padding: 0px;
  }
  &[type="date"] {
    appearance: textfield;
    &::-webkit-calendar-picker-indicator {
      margin-right: 14px;
      color: #444444;
      &:hover {
        background: none;
      }
    }
    // &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {}
    // &::-webkit-clear-button {}
  }
  &.inpt-large {
    width: 100%;
  }

  &.inpt-bg {
    background-color: $background-color;
  }
}

.slct {
  height: $form-element-height;
  text-indent: 14px;
  border: none;
  margin-bottom: 4px;
  padding: 0px;
  border-radius: 4px;
  appearance: none;
  background-color: white;
  &:hover {
    background: url("data:image/svg+xml;charset=UTF-8,%3c?xml version='1.0' encoding='utf-8'?%3e%3csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 4.9 10' style='enable-background:new 0 0 4.9 10;' xml:space='preserve'%3e%3cstyle type='text/css'%3e.st0%7bfill:%23444444;%7d%3c/style%3e%3cg id='Layer_1'%3e%3cpolygon class='st0' points='3.5,4.1 2.5,5.9 1.5,4.1 '/%3e%3c/g%3e%3c/svg%3e") no-repeat 95% 50%, white;
  }

  &.slct-large {
    width: 100%;
  }
}

.form-group {
  margin-bottom: 4px;
  .inpt, .slct, .btn {
    margin-bottom: 0px;
  }
  &.inpt-icon {
    position: relative;
    .btn-icon {
      position: absolute;
      right: 0px;
      top: 0px;
    }
  }
}
