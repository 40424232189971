



































































































































































































































































































































@import "@/assets/scss/variables.scss";

@import "bulma/sass/utilities/initial-variables";

$size-large: 1.2rem;

@import "bulma/sass/utilities/functions";
@import "bulma/sass/utilities/derived-variables";
@import "bulma/sass/utilities/mixins";
@import "bulma/sass/utilities/controls";
@import "bulma/sass/elements/icon";
@import "bulma/sass/elements/button";
@import "bulma/sass/form/_all";

header {
  padding: 0px 17px 0px 12px;

  .header {
    select {
      padding-left: 50px;
      appearance: none;
      font-weight: 600;
      color: #4a4a4a;
      background-color: $light;
      padding-right: 30px;
      overflow:hidden; 
      white-space:nowrap; 
      text-overflow:ellipsis;
      &:disabled {
        opacity: 1;
      }
    }

    .input, .select {
      border-color: #dbdbdb;
      box-shadow: none;
    }

    .icon.is-right {
      pointer-events: all !important;
      cursor: pointer;
    }

    .icon svg {
      width: 24px;
      height: 24px;
      color: $dark;
    }
  }

  .filters {
    background-color: $light;
    outline: none;

    .field {
      margin-bottom: 0;

      .control {
        .select {
          height: 52px;
          select {
            color: $secondary-color;
            border-radius: 0;
            font-weight: 400;
            font-size: 1rem;
            height: 100%;
            border-top: 0px;
          }
          &::after {
            right: 1.125em;
            width: 0.5rem;
            height: 0.5rem;
            border: 2px solid transparent;
            border-right: 0px;
            border-top: 0px;
            margin-top: -0.4rem;
            border-color: $grey_neutral_5;
          }
        }
      }
      .button.close {
        height: 52px;
        border-radius: 0;
        font-size: 1rem;
        color: $danger-color;
        border-top: 0px;
        &.is-fullwidth {
          justify-content: flex-start;
        }
        .icon {
          margin-left: calc(-0.4em);
          margin-right: 0.4em;
          svg {
            color: $danger-color;
          }
        }
      }

      .icon {
        svg {
          color: $grey_neutral_5;
        }
      }
      &.move-column {
        height: 52px;
        display: flex;
        border: 1px solid #dbdbdb;
        border-top: 0px;
        border-radius: 0;
        padding-left: 5px;

        svg {
          height: 22px;
          color: #757575;
        }
      }
    }
  }
}
