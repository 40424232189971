













































.about{
  a.close {
    display: block;
    text-align: right;
    i{
      font-size: 20px;
      font-weight: 600;
    }
  }

  h3{
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  p{
    margin-bottom: 1rem;
  }
}

