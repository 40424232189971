































































@import "@/assets/scss/variables.scss";
.shortcuts {
  display: flex;
  align-items: center;
  padding: 0.6rem 2.5rem;

  @include breakpoint(mobile) {
    font-size: 13px;
  }

  @media print {
    display: none;
  }

  .reset {
    background-color: transparent;
    color: $font-color;
    text-shadow: none;
    font-weight: 400;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    margin-left: auto;

    @include breakpoint(mobile) {
      display: none;
    }
  }

  select {
    background: transparent;
    border: none;
    color: $secondary-color;
    margin-left: 10px;
    outline: none;
  }
}
